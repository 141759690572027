import Router from 'svelte-spa-router'

import Home from './views/public/home.svelte'
import Infos from './views/public/infos.svelte'
import Realisations from './views/public/realisations.svelte'
import NotFound from './views/404.svelte'

let routes = {
  '/': Home,
  '/infos': Infos,
  '/realisations': Realisations,
  '*': NotFound,
}

export default routes
