<script>
	import { onMount } from 'svelte'
	import Layout from './layout/main.svelte'

	let realisations = [];

	onMount(async () => {
		const result = await fetch('data/realisations.json');
		realisations = await result.json();
	});
</script>

<style>
.liste{ padding: 2rem 0 0 0; margin: 0; list-style: none; display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2rem }
.liste > li { margin: 0 0 1.5rem 0 }
.liste > li figure { padding: 0; margin: 0}

.lien{ position: relative; padding-bottom: 55%; display:block; margin-bottom: 1rem; overflow: hidden;transition: all 300ms ease;  }

.lien:before{ content:""; transition: all 300ms ease; transform: translateY(100%); display: block; position: absolute; z-index: 2; top:0;left:0;width:100%;height: 100%; background: rgba(0, 0, 0, .85)}
.lien:hover:before,
.lien:focus:before,
.lien:active:before{ transform: translateY(0) }

.lien:after{ content: "\f002"; transition: all 300ms ease 0.15s; transform: translateY(100%); color: #fff; z-index: 2; position: absolute; margin: auto; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; font-size: 2rem; }
.lien:hover:after,
.lien:focus:after,
.lien:active:after{ transform: translateY(0) }

.lien img{ position: absolute; top:0; left:0; max-width: 100%; width: 100%; height:100%; object-fit: cover; object-position: top; }
@media (max-width:767px){
	.liste{ grid-template-columns: 1fr }
}

.tools{ padding: 0; margin: 0.8rem 0 0 ; list-style: none }
.tools li{ display: inline-block; background: var(--blue-darker); padding: 5px 8px; margin: 0 3px 0 0; font-size: 0.9rem; }
</style>

<Layout>
  <h1>Travaux persos</h1>
  <ul class="liste">
    {#each realisations as realisation}
    <li>
      <figure>
        <a href={realisation.url} target="_blank" rel="noreferrer" class="lien icon-after" aria-label={realisation.title}>
          <img src={realisation.image} alt="" />
        </a>
        <figcaption>
          <span>{realisation.title}</span>
					<ul class="tools">
						{#each realisation.tools as tool}
						<li>{tool}</li>
						{/each}
					</ul>
        </figcaption>
      </figure>
    </li>
		{:else}
			<p>Chargement...</p>
    {/each}
  </ul>
</Layout>
