<script>
	import { onMount, createEventDispatcher } from 'svelte';
	import {
		Scene, PerspectiveCamera, WebGLRenderer, BoxGeometry, Mesh, MeshBasicMaterial,
		AmbientLight, SpotLight, Color, Vector3, OrbitControls, LinearToneMapping,
		PCFShadowMap, CubeTextureLoader, BoxBufferGeometry, Clock, Vector2, TextureLoader,
		MeshPhongMaterial, MixOperation, PointLight, Lensflare, LensflareElement
	} from 'three-full'

	// Composer
	import { EffectComposer, RenderPass, ShaderPass, CopyShader } from 'three-full'

	// Composer shaders
	import { FilmShader, FXAAShader, UnrealBloomPass } from 'three-full'

	const dispatch = createEventDispatcher()

  function loaded() {
    dispatch('loaded')
  }

	var camera, scene, canvas, controls, renderer;
	camera = { position: { x: 0, y:0, z:0 } }
	var mousePosition = { x: 0, y: 0, z: 0 }
	var cubes = [];
	var composer, composerPasses;
	var clock = new Clock()
	var options = {
		general: {
			optionsPanel: false,
			animation: true,
			postProcessing: true,
			rotateCameraAuto: true,
			controls: 'orbit' // 'orbit' or 'trackball'
		},
		bloom: { threshold: 0.55, strength: 0.7, radius: 0.8 },
		film: { count: 1000, sIntensity: 0.3, nIntensity: 0.1 }
	}

	onMount(() => {

		// INIT
		initScene();
		initCubes();
		initLensflares();
		initOrbit();
		if (options.general.postProcessing) {
			initComposerPasses();
			initComposer();
		}

		loaded();
		// window.scene = scene;
		animate();

		function animate () {
			requestAnimationFrame( animate );
			updateCamera();
			controls.update()

			for ( var i = 0, il = cubes.length; i < il; i ++ ) {
				cubes[ i ].rotation.x += 0.001;
				cubes[ i ].rotation.y += 0.001;
				if(cubes[ i ].position.y >= 4000){
					cubes[ i ].position.y = -4000;
				}
				cubes[ i ].position.y += 3;
			}

			if (options.general.postProcessing) {
				updateComposerPasses();
				composer.render();
			} else {
				renderer.render( scene, camera );
			}
		};
	});

	function updateCamera (){
		// this.camera.lookAt(new Vector3(0, 0, 0))
		camera.position.x = camera.position.x * Math.cos(1 / 4000) + camera.position.z * Math.sin(1 / 4000)
		camera.position.z = camera.position.z * Math.cos(1 / 4000) - camera.position.x * Math.sin(1 / 4000)

		// Camera offset
		let multiplicateur = 0.3
		let rotSpeed = 0.005
		let x = camera.position.x
		// let y = camera.position.y
		let z = camera.position.z
		let mouseCameraOffsetX = mousePosition.x * ((x * Math.cos(rotSpeed) + z * Math.sin(rotSpeed)) - x)
		let mouseCameraOffsetZ = mousePosition.x * ((z * Math.cos(rotSpeed) - x * Math.sin(rotSpeed)) - z)
		let mouseCameraOffsetY = mousePosition.y * multiplicateur

		// Apply offset
		camera.position.x += mouseCameraOffsetX
		camera.position.y += mouseCameraOffsetY
		camera.position.z += mouseCameraOffsetZ
	}

	function initScene (){

		scene = new Scene();
		scene.name = 'Scene'
		scene.background = new Color(0x050505)

		camera = new PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.1, 1000000)
		camera.name = 'Main camera'
		camera.lookAt(new Vector3(0, 0, 0))
		camera.position.set(1500, 0, 0)

		canvas = document.querySelector('#threejs canvas')
		canvas.width = window.innerWidth
		canvas.height = window.innerHeight

		// var renderer = new WebGLRenderer();
		// renderer.setSize( window.innerWidth, window.innerHeight );
		renderer = new WebGLRenderer({ canvas: canvas, antialias: false })
		renderer.setViewport(0, 0, window.innerWidth, window.innerHeight)
		renderer.setPixelRatio(window.devicePixelRatio)
		renderer.toneMapping = LinearToneMapping
		renderer.shadowMap.enabled = true
		renderer.shadowMap.type = PCFShadowMap // BasicShadowMap, PCFShadowMap or PCFSoftShadowMap
		renderer.gammaInput = true
		renderer.gammaOutput = true

		let lightAmbient = new AmbientLight(0xffffff)
		lightAmbient.name = 'AmbientLight'
		lightAmbient.visible = true
		lightAmbient.intensity = 0.35
		scene.add(lightAmbient)

		var lightPoint = new PointLight( 0xffffff, 1, 1000 );
		lightPoint.position.set( 200, 200, 200 );
		scene.add( lightPoint );

	  // SpotLight
	  // This light gets emitted from a single point in one direction,
	  // along a cone that increases in size the further from the light it gets.
	  // This light can cast shadows
		/*
	  let spotLight = new SpotLight(0xffffff)
	  spotLight.name = 'SpotLight'
	  spotLight.visible = true
	  spotLight.position.set(-2, -2, -2)
	  // spotLight.position.set(0, 3, 0.5)
	  spotLight.position.multiplyScalar(100)
	  spotLight.intensity = 0.78
	  spotLight.angle = 0.13
	  spotLight.castShadow = true
	  spotLight.shadow.mapSize.width = 2048
	  spotLight.shadow.mapSize.height = 2048
	  spotLight.shadow.camera.near = 250
	  spotLight.shadow.camera.far = 1000
	  spotLight.shadow.camera.fov = 60
	  scene.add(spotLight)
		*/

	  // SpotLight
	  // This light gets emitted from a single point in one direction,
	  // along a cone that increases in size the further from the light it gets.
	  // This light can cast shadows
		/*
	  let spotLight2 = new SpotLight(0xffffff)
	  spotLight2.name = 'SpotLight 2'
	  spotLight2.visible = true
	  spotLight2.position.set(2, 2.5, 5)
	  spotLight2.position.multiplyScalar(100)
	  spotLight2.intensity = 0.78
	  spotLight2.angle = 0.13
	  spotLight2.castShadow = true
	  spotLight2.shadow.mapSize.width = 2048
	  spotLight2.shadow.mapSize.height = 2048
	  spotLight2.shadow.camera.near = 250
	  spotLight2.shadow.camera.far = 1000
	  spotLight2.shadow.camera.fov = 60
	  scene.add(spotLight2)
		*/

	}

	function initCubes(){

		// textures
		var cubeTexture = new CubeTextureLoader()
					.setPath( 'textures/cube/pisa/' )
					.load( [ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ] );
		var bmap = new TextureLoader().load( 'textures/map_fissure_cube.jpg' );

		// material
		var oldMaterial = new MeshPhongMaterial({
		  color      :  0x267dd4,
		  emissive   :  0x267dd4,
		  specular   :  0xffffff,
		  shininess  :  90,
		  bumpMap    :  bmap,
		  bumpScale  :  10,
			envMap: cubeTexture,
			// map        :  smap,
			// transparent: true,
			// opacity: 0.5,

			// If you use THREE.MixOperation for material's combine parameter,
			// then reflectivity parameter controls how much of environment map is blended with the base material.
			combine: MixOperation,
			reflectivity: 0.7 ,
		});

		// geometry
		var geometry = new BoxBufferGeometry(200, 200, 200);

		for ( var i = 0; i < 120; i ++ ) {
			var mesh = new Mesh( geometry, oldMaterial );
			mesh.position.x = Math.random() * 10000 - 5000;
			mesh.position.y = Math.random() * 10000 - 5000;
			mesh.position.z = Math.random() * 10000 - 5000;
			mesh.rotation.x = Math.random() * (2 * Math.PI);
			mesh.rotation.y = Math.random() * (2 * Math.PI);
			mesh.scale.x = mesh.scale.y = mesh.scale.z = Math.random() * 3 + 1;
			scene.add( mesh );
			cubes.push( mesh );
		}

	}

	function initLensflares(){

		var light = new PointLight( 0xffffff, 1.5, 2000 );
		light.position.set(0, 3000, 0)

		var textureLoader = new TextureLoader();

		var textureFlare0 = textureLoader.load( "textures/lensflare/lensflare0.png" );
		var textureFlare1 = textureLoader.load( "textures/lensflare/lensflare1.png" );
		var textureFlare2 = textureLoader.load( "textures/lensflare/lensflare2.png" );
		var textureFlare3 = textureLoader.load( "textures/lensflare/lensflare3.png" );

		var lensflare = new Lensflare();

		lensflare.addElement( new LensflareElement( textureFlare0, 512, 0 ) );
		// lensflare.addElement( new LensflareElement( textureFlare1, 512, 0 ) );
		lensflare.addElement( new LensflareElement( textureFlare2, 60, 0.6 ) );
		lensflare.addElement( new LensflareElement( textureFlare3, 30, 0.6 ) );

		light.add( lensflare );

		scene.add( light );

	}

	function initComposer(){
    let renderScene = new RenderPass(scene, camera)
    let copyShader = new ShaderPass(CopyShader)
    copyShader.renderToScreen = true // La dernière passe doit avoir renderToScreen = true
    composer = new EffectComposer(renderer)

    // Add passes to composer
    composer.addPass(renderScene)
    composer.addPass(composerPasses.passBloom)
    composer.addPass(composerPasses.passFXAA)
    // composer.addPass(composerPasses.passFilm)
    composer.addPass(copyShader)
  }

	function initComposerPasses() {
    // Create passes
    let passBloom = new UnrealBloomPass(new Vector2(window.innerWidth, window.innerHeight), options.bloom.strength, options.bloom.radius, options.bloom.threshold)
    let passFilm = new ShaderPass(FilmShader)
    passFilm.uniforms.grayscale.value = 0
    let passFXAA = new ShaderPass(FXAAShader)
    passFXAA.uniforms['resolution'].value.set(1 / window.innerWidth, 1 / window.innerHeight)

    // Save passes in state
    let passes = {
      passBloom,
      passFilm,
      passFXAA
    }
    composerPasses = passes
  }

	function updateComposerPasses() {
    // Bloom
    composerPasses.passBloom.threshold = options.bloom.threshold
    composerPasses.passBloom.strength = options.bloom.strength
    composerPasses.passBloom.radius = options.bloom.radius

    // Film
    composerPasses.passFilm.uniforms['time'].value = clock.getElapsedTime()
    composerPasses.passFilm.uniforms['sCount'].value = options.film.count
    composerPasses.passFilm.uniforms['sIntensity'].value = options.film.sIntensity
    composerPasses.passFilm.uniforms['nIntensity'].value = options.film.nIntensity

    // FXAA
    composerPasses.passFXAA.uniforms['resolution'].value.set(1 / window.innerWidth, 1 / window.innerHeight)
  }

	function initOrbit (){
		// Le deuxième argument est l'élément du DOM sur lequel seront ajoutés tous les écouteurs
		controls = new OrbitControls(camera, canvas)
		controls.enabled = true
		controls.target = new Vector3(0, 0, 0)

		// How far you can dolly in and out ( PerspectiveCamera only )
		// controls.minDistance = 35
		// controls.maxDistance = 35

		// How far you can zoom in and out ( OrthographicCamera only )
		// controls.minZoom = 0
		// controls.maxZoom = Infinity

		// How far you can orbit vertically, upper and lower limits.
		// Range is 0 to Math.PI radians.
		// controls.minPolarAngle = (Math.PI / 4) // radians
		// controls.maxPolarAngle = (Math.PI / 4) * 3 // radians

		// How far you can orbit horizontally, upper and lower limits.
		// If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
		// controls.minAzimuthAngle = - Infinity // radians
		// controls.maxAzimuthAngle = Infinity // radians

		// Set to true to enable damping (inertia)
		// If damping is enabled, you must call controls.update() in your animation loop
		// controls.enableDamping = true
		// controls.dampingFactor = 0.25

		// This option actually enables dollying in and out left as "zoom" for backwards compatibility.
		// Set to false to disable zooming
		controls.enableZoom = true
		controls.zoomSpeed = 0.8

		// Set to false to disable rotating
		// controls.enableRotate = true
		// controls.rotateSpeed = 1.0

		// Set to false to disable panning
		controls.enablePan = true
		// controls.keyPanSpeed = 7.0 // pixels moved per arrow key push

		// Set to true to automatically rotate around the target
		// If auto-rotate is enabled, you must call controls.update() in your animation loop
		// controls.autoRotate = true
		// controls.autoRotateSpeed = 2.0 // 30 seconds per round when fps is 60

		// Set to false to disable use of the keys
		// controls.enableKeys = true

		// The four arrow keys
		// controls.keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 }

		// Mouse buttons
		// controls.mouseButtons = { ORBIT: MOUSE.LEFT, ZOOM: MOUSE.MIDDLE, PAN: MOUSE.RIGHT }

		controls.update()

	}

	function onMouseMove (e){
		// position souris (de -0.5 à 0.5 en x et y)
		mousePosition.x = ((e.pageX - document.querySelector('#threejs').getBoundingClientRect().left) / document.querySelector('#threejs').clientWidth) - 0.5
		mousePosition.y = ((e.pageY - document.querySelector('#threejs').getBoundingClientRect().top) / document.querySelector('#threejs').clientHeight) - 0.5
	}

	window.addEventListener('resize', onResize);

	function onResize (e){
		camera.aspect = window.innerWidth / window.innerHeight
		camera.updateProjectionMatrix()

		renderer.setSize(window.innerWidth, window.innerHeight)
	}

</script>

<style>
	.app-threejs{ position:absolute; width: 100%; height:100vh; top:0; left:0; overflow:hidden }
	/* La scene three.js s'adapte automatique aux dimensions de ce container */
	.Threejs{ width: 100%; height:100%; position:fixed; top:0; left:0; z-index:10; }
	.options{ color:#000; position: absolute; top: 0; right: 0; background: #fff; padding: 20px; z-index: 11; }
</style>

<div class="app-threejs">

	{#if options.general.optionsPanel}
	<div class="options">
		<div class="option">
			<label for="">PostProcessing</label>
			<input tabindex="-1" type="checkbox" bind:checked="{options.general.postProcessing}">
		</div>
		<div class="option">
			<label for="">Bloom threshold</label>
			<input tabindex="-1" type="range" bind:value="{options.bloom.threshold}" min="0" max="2" step="0.01">
			<span>{options.bloom.threshold}</span>
		</div>
		<div class="option">
			<label for="">Bloom strength</label>
			<input tabindex="-1" type="range" bind:value="{options.bloom.strength}" min="0" max="3" step="0.1">
			<span>{options.bloom.strength}</span>
		</div>
		<div class="option">
			<label for="">Bloom radius</label>
			<input tabindex="-1" type="range" bind:value="{options.bloom.radius}" min="0" max="2" step="0.1">
			<span>{options.bloom.radius}</span>
		</div>
		<div class="option">
			<label for="">Camera position XYZ</label>
			<output>{ Math.round(camera.position.x) } { Math.round(camera.position.y) } { Math.round(camera.position.z) }</output>
		</div>
	</div>
	{/if}

	<div class="Threejs" id="threejs">
		<canvas on:mousemove="{ onMouseMove }"></canvas>
	</div>
</div>
