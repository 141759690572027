<script>
  import { onMount, tick } from 'svelte';
  import gsap from 'gsap';
  import Layout from './layout/main.svelte'

  let experiences = []
  let connaissances = []
  let formations = []

  onMount(async () => {
    // Expériences
    const resultExperiences = await fetch('data/experiences.json');
		experiences = await resultExperiences.json();
    await tick(); // wait the DOM to be updated
    gsap.from('.bloc-experiences ol li', { duration:0.25, autoAlpha:0, stagger: 0.1 }, 0.1)

    // Formations
    const resultFormations = await fetch('data/formations.json');
		formations = await resultFormations.json();

    // Connaissances
    const resultConnaissances = await fetch('data/connaissances.json');
		connaissances = await resultConnaissances.json();
    await tick(); // wait the DOM to be updated
    gsap.timeline({})
      .from('.bloc-connaissances ul li', { duration:0.25, autoAlpha:0, stagger: 0.1 }, 0.1)
      .fromTo('.bloc-connaissances .barre', { scaleX: 0, transformOrigin:'left center' }, { duration:0.25, stagger:0.1, scaleX: function(i, el){
        return el.parentNode.dataset.pourcentage / 100
      } });
  });
</script>

<style>
  .blocs{ display:grid; grid-template-columns: 1fr 1fr; grid-gap:30px }

  .bloc{ margin:0 0 3.5rem 0 }
  .bloc h2{ color:var(--blue) }
  .bloc ul,
  .bloc ol{ padding: 0; margin: 0; list-style: none; position: relative; }

  .bloc.chronologie ol{ padding-left:20px }
  .bloc.chronologie ol:before{ content: ""; position: absolute; left: 0; height: 100%; width: 2px; top: 0; background: #fff; }
  .bloc.chronologie ol li{ position: relative; padding: 0 0 1rem 0px; }
  .bloc.chronologie ol li:before{ content: ""; position: absolute; left: -25px; height: 12px; width: 12px; top: 8px; background: var(--blue); }
  .bloc.chronologie h3{ color: #fff; margin: 0.9rem 0 0.3rem 0; }
  .bloc.chronologie h3 + span{ color:var(--blue); font-weight: bold; }
  .bloc.chronologie p{ color:darkgrey }
  .bloc.chronologie time{ display: inline-block; background: rgba(27, 27, 27, 0.65); padding: 0.4rem 1rem; font-size: 0.8rem; color: #b7b7b7; }

  .bloc-connaissances ul{ padding: 0; margin: 0; list-style: none }
  .bloc-connaissances ul li { margin: 0 0 0.8rem 0; font-weight: 600; }
  .bloc-connaissances ul li:last-child { margin: 0 }
  .bloc-connaissances ul li > div:nth-child(1){ padding: 0.3rem 0; }
  .bloc-connaissances ul li > div:nth-child(2){ background: #fff; display:flex; width:100%; height:0.4rem; position: relative; overflow: hidden }
  .bloc-connaissances ul li span{ font-weight: normal; }
  .bloc-connaissances .barre{ background: var(--blue); display: block; width: 100%; outline: 5px solid var(--blue); }
  @media (max-width:767px){
  	.blocs{ grid-template-columns: 1fr }
  }
</style>

<Layout>
  <h1>À propos</h1>
  <div class="blocs">
    <div>

  		<div class="bloc bloc-experiences chronologie">
  			<h2>Expériences</h2>
  			<ol>
          {#each experiences as experience }
  				<li>
  					<time>{experience.date}</time>
  					<h3>{experience.title}</h3>
  					<span>{experience.society}</span>
            {#if experience.description}
  					<p> {experience.description} </p>
            {/if}
  				</li>
          {/each}
  			</ol>
  		</div>

  		<div class="bloc bloc-formation chronologie">
  			<h2>Formation</h2>
  			<ol>
          {#each formations as formation }
  				<li>
            <time>{formation.date}</time>
            <h3>{formation.title}</h3>
            <span>{formation.society}</span>
  				</li>
          {/each}
  			</ol>
  		</div>

  	</div>
    <div>

  		<div class="bloc bloc-connaissances">
  			<h2>Connaissances</h2>
  			<ul>
          {#each connaissances as connaissance}
  				<li>
  					<div>{connaissance.title} {#if connaissance.details}<span>({connaissance.details})</span>{/if}</div>
  					<div data-pourcentage={connaissance.percent}><span class="barre"></span></div>
  				</li>
          {/each}
  			</ul>
  		</div>

  		<div class="bloc bloc-env">
  			<h2>Environnement</h2>
  			<p>Atom, Notepad++, Windows, Android</p>
  		</div>

  		<div class="bloc bloc-git">
  			<h2>Réseaux</h2>
  			<a href="https://github.com/classikd/" target="_blank">https://github.com/classikd/</a>
  		</div>

  	</div>
  </div>
</Layout>
