<script>
  import { onMount } from 'svelte';
  import { link } from 'svelte-spa-router';
  import active from 'svelte-spa-router/active';
  import gsap from 'gsap';

  let menuOpened = false;
  let tl;

  // Dom references
  let modalBtn;
  let modal;

  onMount(() => {
    tl = gsap.timeline({ paused: true });
    tl
      .from(modal, { duration:0.25, autoAlpha:0, y:-50 })
      .fromTo(modal.querySelectorAll('li'), { autoAlpha:0, x:30 }, {  duration:0.25, autoAlpha:1, x:0, stagger:0.1 });
  });

	const onKeydown = e => {
		if (e.key === 'Escape' && menuOpened) {
			toggleMenu();
			return;
		}
		if (e.key === 'Tab' && menuOpened) {
      trapFocus(e);
		}
	};

  function toggleMenu() {
    if(menuOpened){
      tl.timeScale(2)
      tl.reverse();
      modalBtn.focus();
      document.body.style.overflow = 'visible'
    } else {
      tl.timeScale(1)
      tl.play();
      document.body.style.overflow = 'hidden'
    }
    modalBtn.setAttribute('aria-expanded', !menuOpened);
    menuOpened = !menuOpened;
  }

  function trapFocus(e) {
    const nodes = modal.querySelectorAll('*');
    const tabbable = Array.from(nodes).filter(n => n.tabIndex == 0);

    let index = tabbable.indexOf(document.activeElement);
    if (index === -1 && e.shiftKey) index = 0;

    index += tabbable.length + (e.shiftKey ? -1 : 1);
    index %= tabbable.length;

    tabbable[index].focus();
    e.preventDefault();
  }

</script>


<style>
  .modal-menu{ position: fixed; top: 0; left: 0; width:100%; height:100%; background:rgba(0, 0, 0, .95); z-index: 15; align-items: center; display: flex; }

  .main-menu{ list-style:none; color:#fff; padding: 0 }
  .main-menu li { margin: 1rem 0 }
  .main-menu li a { display:inline-block; outline:0; position: relative; font-size: 3.2rem; text-decoration: none; font-family: 'Archivo'; }
  .main-menu li a:after { content:""; display: block; height:2px; background: var(--blue); position: absolute; bottom:0; left:0; width:100%; transition: all 300ms ease; transform:scaleX(0); transform-origin: left center }
  .main-menu li a:hover:after,
  .main-menu li a:focus:after,
  .main-menu li a:active:after { transform:scaleX(1) }
  :global(.main-menu li a.active) { color: var(--blue) } /* need :global because the router adds the class directly */
  @media (min-width:1599px){
  	.main-menu li a { font-size: 4.2rem; }
  }

  .main-menu-btn { position: fixed; top: 0; left: 0; color: #fff; z-index: 99;text-transform: uppercase; width: 5rem; height: 5rem; font-size: 1.4rem; background: none; border: none; cursor: pointer; }
  .main-menu-btn:hover,
  .main-menu-btn:focus,
  .main-menu-btn:active { background: var(--blue) }
</style>

<svelte:window on:keydown={onKeydown}/>

<header>

  <button
  class="main-menu-btn"
  id="main-menu-btn"
  bind:this={modalBtn}
  on:click={toggleMenu}
  aria-expanded="false"
  aria-controls="modal-menu"
  aria-haspopup="true"
  >
    {#if menuOpened}
    <i class="fas fa-times" aria-hidden="true"></i>
    {:else}
    <i class="fas fa-bars" aria-hidden="true"></i>
    {/if}
    <span class="sr-only">Menu principal</span>
  </button>

  <div class="modal-menu" bind:this={modal}>
    <div class="container">
      <nav aria-label="Menu principal">
        <ul class="main-menu">
          <li><a href="/" use:link use:active on:click={toggleMenu} tabindex="0">Accueil</a></li>
          <li><a href="/infos" use:link use:active on:click={toggleMenu} tabindex="0">À propos</a></li>
          <li><a href="/realisations" use:link use:active on:click={toggleMenu} tabindex="0">Travaux</a></li>
          <li><a href="mailto:postmaster@classik-design.com" tabindex="0">Contact</a></li>
        </ul>
      </nav>
    </div>
  </div>

</header>
