<script>
	import { fly, fade } from 'svelte/transition';
</script>

<style>
	.layout{ background: rgba(0, 0, 0, 0.95) }
</style>

<div class="layout" out:fade in:fly="{{ y: 200, duration: 800 }}">
	<div class="layout-container container">
		<slot></slot>
	</div>
</div>
