<script>
  import Router from 'svelte-spa-router'
  import routes from './routes'
  import screenfull from 'screenfull'

	import Header from './views/public/layout/header.svelte'
  import Threejs from './views/components/threejs.svelte'
	import Breadcrumbs from './views/components/breadcrumbs.svelte'
	import Loading from './views/components/loading.svelte'

  let loading = false
  let isFullscreen = false
  let isPlayingBgSound = false
  let sound = new Audio('sounds/atmosphere.mp3');
  sound.loop = true;
  sound.volume = 0.1;

  function threeJsLoaded(){
    loading = false
  }

  function goToContent(element){
    // TODO: Voir si possible utiliser reference dom depuis autre composant avec bind
    var mainContent = document.querySelector(element);
    mainContent.scrollIntoView({ behavior: 'smooth' });
    mainContent.focus();
  }

  function toggleFullscreen(){
    isFullscreen ? screenfull.exit() : screenfull.request()
    isFullscreen = !isFullscreen
  }

  function toggleSound(){
    isPlayingBgSound ? sound.pause() : sound.play()
    isPlayingBgSound = !isPlayingBgSound
  }

</script>

<style>
  main{ position:relative; z-index:10; outline: 0; }
  .skip-links{ position: absolute; z-index: 999; left: 50%; transform: translateX(-50%);  }
  .skip-links a{ opacity: 0; transition: opacity 300ms ease; background:var(--blue); color:#fff; padding: 1rem 2rem; text-decoration: none; display: inline-block; }
  .skip-links a:focus{ opacity:1 }
  .control-bar{ position:fixed; right:0; bottom:0; z-index:11 }
  .control-bar button{ width: 4rem; height: 4rem; border: none; font-size: 1.3rem; cursor: pointer; color: #fff; background: transparent; }
  .control-bar button:hover,
  .control-bar button:focus,
  .control-bar button:active{ background: var(--blue); color:#fff }
</style>

<div class="skip-links">
  <a href="javascript:void(0)" on:click|preventDefault={() => goToContent('#main-content-anchor')}>Aller au contenu</a>
  <a href="javascript:void(0)" on:click|preventDefault={() => goToContent('#main-menu-btn')}>Aller au menu</a>
</div>

<Threejs on:loaded={threeJsLoaded} />

<div class="control-bar">
 <button on:click={() => toggleFullscreen()} aria-hidden="true">
   {#if !isFullscreen}
    <i class="fas fa-expand"></i>
   {:else}
    <i class="fas fa-compress-alt"></i>
   {/if}
 </button>
 <button on:click={() => toggleSound()} aria-label={ isPlayingBgSound ? 'Couper la musique de fond' : 'Activer la musique de fond' }>
   {#if isPlayingBgSound}
    <i class="fas fa-volume-up"></i>
   {:else}
    <i class="fas fa-volume-off"></i>
   {/if}
 </button>
</div>

{#if loading}
  <Loading />
{:else}
  <!-- <Notification {notification} {visible} /> -->
  <Header />
  <!-- <Breadcrumbs /> -->
  <main id="main-content-anchor" tabindex="0">
    <Router {routes}/>
  </main>
{/if}
